<template>
  <MainLayout>
    <template v-slot:navbar-title>Добавить</template>
    <CreateFormLayout>
      <template v-slot:title__text>
        Опекун
      </template>
      <template v-slot:title-flex-right>
        <div></div>
      </template>
      <!--      <template>-->
      <!--        <FormCardTitle-->
      <!--            title="Выбрать родителя из существующих пользователей"-->
      <!--        ></FormCardTitle>-->
      <!--        <div>-->
      <!--          <FormInputBlock>-->
      <!--            <ValidationAutocompleteField-->
      <!--                label="Родители"-->
      <!--                validate-name="родители"-->
      <!--                v-model="selectedId"-->
      <!--                :disabled="!!phone"-->
      <!--                :search-function="parentsSearch"-->
      <!--            />-->
      <!--          </FormInputBlock>-->
      <!--        </div>-->
      <!--      </template>-->
      <template>
        <!--    Данные компании    -->
        <div>
          <FormCardTitle title="Информация об опекунах"></FormCardTitle>

          <FormInputBlock>
            <ValidationInputField
              label="Телефон"
              rules="phone|required"
              mask="+7 (9##) ###-##-##"
              v-model="phone_number"
              validate-name="Телефон"
            ></ValidationInputField>

            <ValidationInputField
              label="Пользователь (необязательно)"
              validate-name="пользователь"
              :rules="'min:1|max:40'"
              v-model="user"
              :disabled="!disabledEdit"
            />
            <ValidationInputField
              label="Комментарий (необязательно)"
              validate-name="комментарий"
              :rules="'min:1|max:40'"
              v-model="comments"
              :disabled="!disabledEdit"
            />
          </FormInputBlock>
        </div>
        <!--    Данные компании    -->
      </template>

      <template v-slot:btns="{ handleSubmit }">
        <a @click="$router.go(-1)" class="btn btn_color_white">
          Отмена
        </a>
        <v-btn
          type="submit"
          @click="handleSubmit(createParents)"
          class="btn btn-green"
        >
          Сохранить
        </v-btn>
      </template>
    </CreateFormLayout>
    <v-overlay :value="loading" z-index="9999">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </MainLayout>
</template>

<script>
import MainLayout from "@/components/layouts/MainLayout";
import CreateFormLayout from "@/components/layouts/CreateFormLayout";
import FormInputBlock from "@/components/createFormBlocks/FormInputBlock";
import ValidationInputField from "@/components/ValidationField/ValidationInputField";
// import ValidationAutocompleteField from "@/components/ValidationField/ValidationAutocompleteField";
import { getAccessGroupsRequest } from "@/helpers/api/access-group";
import router from "@/router";
import FormCardTitle from "@/components/createFormBlocks/FormCardTitle";
// import { getAllUsersClearRequest } from "../../helpers/api/user";
import {
  actionMessage,
  successMessageDictionary
} from "@/helpers/dictionariesHelper/successMessages";
// import phoneFilter from "@/helpers/filters/phoneFilter";
export default {
  name: "ParentsCreateFormPage",
  components: {
    FormCardTitle,
    // ValidationAutocompleteField,
    ValidationInputField,
    FormInputBlock,
    CreateFormLayout,
    MainLayout
  },
  data() {
    return {
      loading: false,
      number: null,
      groupNumber: null,
      currentUser: null,
      disabledEdit: false,

      // parentsSearch: async value => {
      //   return (
      //       await getAllUsersClearRequest({
      //         query: {
      //           search: value.replace(/[^\d+а-яА-ЯёЁ ]/g, "").slice(0, 13)
      //           // ,groups__organization: this.kindergartenId || this.currentOgr
      //         }
      //       })
      //   ).data.results.map(el => ({
      //     text:
      //         this.$store.getters.getCurrentRole == 70
      //             ? phoneFilter.execute(el.phone_number) + " " + el.full_name
      //             : phoneFilter.execute(el.phone_number),
      //     value: el.id
      //   }));
      // },
      userGroupSearch: async value => {
        return (
          await getAccessGroupsRequest({
            query: {
              organization: this.kindergartenId || this.currentOgr,
              ids: value
            }
          })
        ).data.results.map(el => ({
          text: el.title,
          value: el.id
        }));
      }
    };
  },
  props: {
    kindergartenId: {
      type: [String, Number]
    },
    childrenId: {
      type: [String, Number]
    }
  },
  watch: {
    phone_number: async function(val) {
      let data = {
        phone_number: this.phone_number
      };
      if (val?.length == 18) {
        this.$store.dispatch("fetchKindergartenUserInfo", { data }).then(() => {
          this.currentUser = this.$store.getters.getCreateCurrentUserKindergartens;
          this.dadataField();
        });
      }
    }
  },
  computed: {
    currentOgr() {
      return this.$store.getters.getCurrentOrganization;
    },
    phone_number: {
      get() {
        return this.$store.getters.getCreateParentsForm.phone_number;
      },
      set(newValue) {
        this.number = newValue;
        this.$store.commit("setParentsCreateForm", {
          fieldName: "phone_number",
          value: newValue
        });
      }
    },

    userGroups: {
      get() {
        return this.$store.getters.getCreateParentsForm.group;
      },
      set(newValue) {
        this.groupNumber = newValue;
        this.$store.commit("setParentsCreateForm", {
          fieldName: "group",
          value: newValue
        });
      }
    },
    user: {
      get() {
        return this.$store.getters.getCreateParentsForm.user;
      },
      set(newValue) {
        this.$store.commit("setParentsCreateForm", {
          fieldName: "user",
          value: newValue
        });
      }
    },
    comments: {
      get() {
        return this.$store.getters.getCreateParentsForm.comments;
      },
      set(newValue) {
        this.$store.commit("setParentsCreateForm", {
          fieldName: "comments",
          value: newValue
        });
      }
    }
  },

  methods: {
    dadataField() {
      if (this.currentUser.length == 0) {
        this.$store.commit("clearCreateParentsForm", {
          listName: "createParentsForm"
        });
        this.phone_number = this.number;
        this.userGroups = this.groupNumber;
        this.disabledEdit = true;
      } else {
        this.disabledEdit = false;
        this.user = this.currentUser[0].additional_fields[0].value;
        this.comments = this.currentUser[0].additional_fields[1].value;
      }
    },
    createParents() {
      this.loading = true;
      this.$store
        .dispatch("createParentsForm", this.childrenId)
        .then(() => {
          this.$store.dispatch(
            "successNotification",
            actionMessage.add(successMessageDictionary.parent)
          );
        })

        .then(() => {
          this.loading = false;
          router.go(-1);
        });
    }
  },
  // created() {
  //   this.$store.commit("setSearchQuery", {
  //     id: this.$route.params.id
  //   });
  // },

  beforeDestroy() {
    this.$store.commit("clearCreateParentsForm", {
      listName: "createParentsForm"
    });
  }
};
</script>

<style scoped></style>
